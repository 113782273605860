$platform-name: 'migranthelpredesign';
$assets-path: "../assets/" ;

//Fonts
@font-face {
    font-family: "GT Walsheim";
    src: url($assets-path + "GT-Walsheim-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GT Walsheim";
    src: url($assets-path + "GT-Walsheim-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GT Walsheim";
    src: url($assets-path + "GT-Walsheim-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GT Walsheim";
    src: url($assets-path + "GT-Walsheim-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

// Colours
$brand-primary: #A51974;
$brand-secondary: #73649F;
$brand-tertiary: #9c2d2d;
$brand-quarternary: #2B7989;
$footer-bg: #29011b;

$bg-color: #fff;
$bg-dark: #1D1D1D;
$bg-light:#f7f7f7;

$body-colour: #141414;
$border-colour: #ADADAD;

// Accent Colors
$accent-blue:#3a4a7a;
$accent-green:#5aaf8e;
$accent-yellow:#FAE981;
$accent-orange:#f2a253;

// Greyscale
$black: #000;
$white: #fff;

// Logo
$logo: "logo.png";
$logo-width: 231px;
$logo-height: 80px;
$logo-mobile-width: 144px;
$logo-mobile-height: 50px;

// Typography
$font-family-base: "GT Walsheim", sans-serif;

$font-size-base: 16px;
$font-size-large: 20px;
$font-size-small: 14px;

$font-size-h1: 49px;
$font-size-h2: 39px;
$font-size-h3: 31px;
$font-size-h4: 25px;
$font-size-h5: 20px;
$font-size-h6: 16px;

$font-weight-normal: normal;
$font-weight-bold: bold;
$line-height-base: 1.5;

$headings-line-height: 1.4;
$headings-colour: $body-colour;

// Layout
$container-max-width: 1280px;

// Links
$link-colour: $brand-secondary;

// Transitions
$transition-duration: 0.3s;
$transition-duration-slow: 1.5s;
$transition-curve: ease;

// Search
$header-search-margin-right: 15px;

// Search - input
$header-search-input-max-width: 280px;
$header-search-input-margin-right: 0;
$header-search-input-padding: 9.5px 30px;
$header-search-input-placeholder-colour: $body-colour;
$header-search-input-border-radius: 0;
$header-search-input-border-width: 0; // t-r-b-l
$header-search-input-background-colour: tranparent;
$header-search-input-colour: $body-colour;
$header-search-input-focus-background-colour: tranparent;
$header-search-input-focus-colour: $body-colour;

// Search - button
$header-search-button-width: 24px;
$header-search-button-height: 24px;
$header-search-button-background-colour: transparent;
$header-search-button-icon-font-size: 18px;
$header-search-button-icon-colour: $black;
$header-search-button-border-radius: 0;

// Navigation
$nav-background-colour: $bg-dark;

// Menu Admin
$menu-admin-login-link-enabled: false;
$menu-admin-manager-link-enabled: false;
$menu-admin-my-details-link-enabled: false;
$menu-admin-logout-link-enabled: false;

// Top level items
$nav-top-level-item-padding: 20px 15px;
$nav-top-level-item-colour: $white;
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-chevrons-enabled: true;

// Submenus
$nav-submenu-background-colour: $brand-secondary;
$nav-submenu-item-padding: 10px 15px;
$nav-submenu-item-colour: $white;

// Burger button
$burger-btn-background-colour: transparent;
$burger-btn-text-colour: $brand-secondary;

// Nav normal
$nav-normal-mobile-background-colour: $bg-dark;
$nav-normal-mobile-top-level-item-colour: $white;
$nav-normal-mobile-submenu-item-colour: $white;

// Buttons
$btn-background-colour: $brand-primary;
$btn-text-colour: $white;
$btn-border-radius: 5px;
$btn-padding-x: 30px;
$btn-padding-y: 15px;
$btn-font-weight: $font-weight-bold;
$btn-font-size: $font-size-base;
$btn-line-height: 1.25;

//Carousel Homepage 1st section
$carousel-details-style: below; // overlay, adjacent or below

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

//Card
$card-details-background-colour: transparent;
$footer-boxes-count: 4;

// Social icons (https://github.com/RaisingITDev/fresco/wiki/social-icons)
// Social network colours - use these by changing $social-icons-use-brand-colours to true
$twitter-brand-colour: #1da1f2 !default;
$facebook-brand-colour: #3b5998 !default;
$youtube-brand-colour: #ff0000 !default;
$instagram-brand-colour: #833ab4 !default;
$linkedin-brand-colour: #0077b5 !default;
$pinterest-brand-colour: #bd081c !default;
$tripadvisor-brand-colour: #00af87 !default;
$vimeo-brand-colour: #1ab7ea !default;

$social-icons-background-colour: transparent;
$social-icons-colour: $white;
$social-icons-hover-colour: $brand-secondary; // A colour, or 'brand'
$social-icons-width: 40px !default;
$social-icons-height: 40px !default;
$social-icons-font-size: 1.2rem !default;
$social-icons-gap: 5px !default;
$social-icons-border-radius:  none;

// Submenus
$nav-submenu-item-padding: 10px 20px !default;

// Listed post settings
$listed-post-read-more-enabled: true;
